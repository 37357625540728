<template>
  <div>
    <div class="title-path">
      <a href="/">Home</a>
      <small class="fas fa-angle-right mx-1"></small>
      <a href="/users">Users</a>
      <small class="fas fa-angle-right mx-1"></small>
      <a :href="`/users/${_id}`">{{internalUser.email}}</a>
    </div>
    <div class="row issue-wrapper" v-if="internalUser._id">
      <div class="col col-auto-7 issue-col p-4 bg-white">
        <div class="row px-2">
          <div class="col-12 pt-2">
            <h6 class="text-dark text-header">
              Full Name
              <small class="text-secondary cursor-pointer float-right mt-2" v-if="formControl.avatarMenu" v-on:click="toggleAvatar">Change avatar</small>
            </h6>
            <input class="form-control" placeholder="Full name" v-model="internalUser.name" :disabled="!formControl.profile"/>
            <br>
            <h6 class="text-dark text-header">Email Address</h6>
            <input class="form-control" placeholder="Email Address" v-model="internalUser.email" disabled/>
            <br>
            <h6 class="text-dark text-header">Phone Number</h6>
            <input class="form-control" placeholder="Phone Number" v-model="internalUser.phoneNumber" :disabled="!formControl.profile"/>
            <br>
            <h6 class="text-dark text-header">Mobile Number</h6>
            <input class="form-control" placeholder="Mobile Number" v-model="internalUser.mobileNumber" :disabled="!formControl.profile"/>
            <br>
            <div class="row">
              <div class="col">
                <h6 class="text-dark text-header">User Role</h6>
                <ModelSelect
                  v-model="userRole"
                  placeholder="Select user role"
                  :options="options.roles.filter(r => !r.disabled)"
                  :isDisabled="!formControl.profile"
                >
                </ModelSelect>
              </div>
              <div class="col">
                <h6 class="text-dark text-header">Email Notifications</h6>
                <ModelSelect
                  v-model="internalUser.receiveEmail"
                  placeholder="Email Notifications"
                  :options="options.notifications"
                  :isDisabled="!formControl.profile"
                >
                </ModelSelect>
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col">
                <h6 class="text-dark text-header">Ticket Deletion</h6>
                <ModelSelect
                  v-model="internalUser.restrictDelete"
                  placeholder="Restrict user access to deletion"
                  :options="options.invertedBoolean"
                  :isDisabled="!formControl.profile || this.$userData.user.role !== 'Admin' || userRole.value == 'Agent'"
                >
                </ModelSelect>
              </div>
              <div class="col">
                <h6 class="text-dark text-header">Ticket Reassignment</h6>
                <ModelSelect
                  v-model="internalUser.restrictReassign"
                  placeholder="Restrict user access to reassign"
                  :options="options.invertedBoolean"
                  :isDisabled="!formControl.profile || this.$userData.user.role !== 'Admin' || userRole.value == 'Agent'"
                >
                </ModelSelect>
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col">
                <h6 class="text-dark text-header">New Password</h6>
                <input class="form-control" :type="showPassword ? `text` : `password`" placeholder="Reset password" v-model="internalUser.resetPassword" :disabled="!formControl.profile"/>
                <div
                  class="btn btn-muted btn-sm p-3 float-right"
                  @mouseover="showPassword = true"
                  @mouseleave="showPassword = false"
                  style="position: relative; margin-top: -50px; z-index: 999"
                >
                  <i class="text-secondary fas" :class="showPassword ? `fa-eye-slash` : `fa-eye`"/>
                </div>
              </div>
              <div class="col">
                <h6 class="text-dark text-header">Confirm Password</h6>
                <input class="form-control" :type="showConfirmPassword ? `text` : `password`" placeholder="Confirm password" v-model="internalUser.confirmPassword" :disabled="!formControl.profile"/>
                <div
                  class="btn btn-muted btn-sm p-3 float-right"
                  @mouseover="showConfirmPassword = true"
                  @mouseleave="showConfirmPassword = false"
                  style="position: relative; margin-top: -50px; z-index: 999"
                >
                  <i class="text-secondary fas" :class="showConfirmPassword ? `fa-eye-slash` : `fa-eye`"/>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <br>
            <div class="w-100 text-right">
              <div :class="'btn btn-sm border-0 text-light px-3 py-2 d-inline ' + (internalUser.isActive ? 'btn-dark' : 'bg-success')" v-on:click="toggleStatusModal" v-if="formControl.status">
                <i :class="'text-white fas fa-' + (internalUser.isActive ? 'lock' : 'unlock')"></i>&nbsp; {{internalUser.isActive ? 'Deactivate' : 'Activate'}} user
              </div>&nbsp;
              <div class="btn btn-sm btn-primary border-0 px-3 py-2 d-inline" v-on:click="updateUser" v-bind:class="[formControl.profile ? '' : 'element-disabled']">
                <i class="text-white fas fa-save"></i>&nbsp; Update user
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5 issue-col issue-properties shadow-0 border-md-left p-0 m-0" v-bind:class="[formControl.profile ? '' : 'element-disabled']">
        <div class="row px-2 m-0 h-100 w-100">
          <div class="col-md-12 p-4 issue-col bg-white">
            <div class="mb-3 pb-4 border-bottom">
              <div class="property-dynamic">
                <h6 class="pt-3 text-dark text-header">Business Unit</h6>
                <ModelSelect
                  v-model="bunit"
                  placeholder="Select business unit"
                  :options="options.bunits.filter(r => !this.internalUser.bunit.map(q => q._id).includes(r.data._id))"
                  :isDisabled="!formControl.profile"
                >
                </ModelSelect>
              </div>
              <div class="w-100 mt-4 text-right" v-on:click="addBUnit()">
                <div class="btn btn-sm btn-primary border-0 px-3 py-2 d-inline" v-bind:class="[formControl.profile ? '' : 'element-disabled']">
                  <i class="fas fa-plus"></i>&nbsp; Add project
                </div>
              </div>
            </div>
            <h6 class="text-dark text-header">Assigned Business Units</h6>
            <div class="row mt-2" v-for="bunit in internalUser.bunit" v-bind:key="bunit._id">
              <small class="col text-left">
                {{bunit.name}}&nbsp;
                <small><small class="bg-primary-default p-1 px-2 border-rounded text-white" v-html="bunit.code"/></small>
              </small>
              <div class="col-2 text-right">
                <div class="btn btn-sm btn-warning" v-on:click="removeBUnit(bunit)" v-bind:class="[formControl.profile ? '' : 'element-disabled']">
                  <i class="fas fa-minus"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal name="avatar-menu" :transition="`fade`" :width="`620px`" :height="`auto`" :scrollable="true">
      <div class="p-5 row">
        <span v-for="avatar in $avatars" v-bind:key="avatar" class="col col-3">
          <img
            class="profile-avatar m-0 p-3"
            v-bind:class="[`/images/avatars/${avatar}` === internalUser.avatar ? 'avatar-active' : '']"
            v-on:click="setAvatar(`/images/avatars/${avatar}`)"
            :src="$getAvatar(`/images/avatars/${avatar}`)"
          />
        </span>
      </div>
    </modal>
    <modal name="toggle-status" :transition="`fade`" :width="`500px`" :height="`auto`" :scrollable="true">
      <ToggleStatusComponent :user="internalUser" @loader="loader($event)" @updateUserStatus="updateUserStatus"/>
    </modal>
  </div>
</template>

<script>
  import { ModelSelect } from 'vue-search-select'
  import ToggleStatusComponent from  './UserModals/ToggleStatus.vue'

  export default {
    components: {
      ModelSelect,
      ToggleStatusComponent
    },
    data() {
      return {
        internalUser: {},
        formControl: {
          profile: true,
          resetPassword: true,
          avatarMenu: true,
          status: false
        },
        showPassword: false,
        showConfirmPassword: false,
        bunit: {},
        userRole: {},
        options: {
          bunits: [],
          invertedBoolean: [
            { key: 'true', text: "Allow", value: false, disabled: false },
            { key: 'false', text: "Don't Allow", value: true, disabled: false }
          ],
          roles: [
            { key: 'Admin', text: 'Admin', value: 'Admin', disabled: true, data: {isAgent: false, role: 'Admin'} },
            { key: 'Manager', text: 'Manager', value: 'Manager', disabled: false, data: {isAgent: false, role: 'Manager'} },
            { key: 'Manager & Agent', text: 'Manager & Agent', disabled: false, value: 'Manager & Agent', data: {isAgent: true, role: 'Manager'} },
            { key: 'Agent', text: 'Agent', value: 'Agent', disabled: false, data: {isAgent: false, role: 'Agent'} }
          ],
          notifications: [
            { key: 'active', text: 'Receive email notifications', value: true },
            { key: 'inactive', text: 'Suspend email notifications', value: false }
          ]
        },
        loaded: false
      }
    },
    props: {
      _id: String
    },
    methods: {
      loader: function(val){
          this.$emit('loader', val)
      },
      toggleStatusModal: function(){
        this.$modal.toggle('toggle-status');
      },
      async updateUserStatus(){
        var status = !this.internalUser.isActive
        this.loader(true)
        try {
          const response = await this.$http.put(
            `${this.$apiEndpoint}/v1/users/${this._id}`,
            {
              user: {
                isActive: status
              },
              _id: this.internalUser._id
            },
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          if(response.data.user && response.data.user._id) {
            await this.getUser()
            this.setForms()
            this.$alertify({
              group: 'notification',
              title: 'Update successful',
              type: 'success',
              text: 'User successfully ' + (response.data.user.isActive ? 'activated' : 'deactivated')
            })
          }
        } catch (error) {
          this.loader(false)
          console.log(error)
          this.$alertify({
            group: 'notification',
            title: 'Update Failed',
            type: 'warn',
            text: 'Something went wrong, please try again.'
          })
        }
        this.setForms()
        this.$modal.hide('toggle-status');
      },
      async getUser(){
        this.loader(true);
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/users/${this._id}`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          await this.validateUser(response.data.isValid, response.data.exists)
          if (response.data.isValid){
            this.internalUser = response.data.user
            this.userRole = this.options.roles.find(r => r.data.isAgent === this.internalUser.isAgent && r.data.role === this.internalUser.roles)
            this.$console(this.options.roles)
            if(this.internalUser.roles === "Admin"){
              this.options.roles[0].disabled = false
              this.options.roles[1].disabled = true
              this.options.roles[2].disabled = true
              this.options.roles[3].disabled = true
            }
            else if(this.internalUser.roles === "Manager"){
              this.options.roles[0].disabled = true
              this.options.roles[1].disabled = false
              this.options.roles[2].disabled = false
              this.options.roles[3].disabled = false
            }
            else{
              this.options.roles[0].disabled = true
              this.options.roles[1].disabled = false
              this.options.roles[2].disabled = false
              this.options.roles[3].disabled = false
            }
            if(this.$userData.user.roles === "Agent"){
              this.options.roles[0].disabled = true
              this.options.roles[1].disabled = true
              this.options.roles[2].disabled = true
              this.options.roles[3].disabled = false
            }
            this.internalUser.password = ''
            this.internalUser.resetPassword = ''
            this.internalUser.confirmPassword = ''
            await this.getBUnits()
          }
          this.loader(false);
        } catch (error) {
          console.log(error)
          this.loader(false);
        }
      },
      async getBUnits(){
        this.loader(true);
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/bunits`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.options.bunits = response.data.bunits.filter(r => r.isActive).map(r => {
            return { key: r._id, text: `${r.name}`, value: r._id, data: r }
          })
          this.loader(false);
        } catch (error) {
          console.log(error)
          this.loader(false);
        }
      },
      validateUser: async function(isValid, exists){
        this.setForms()
        if(!isValid){
          this.$alertify({
            group: 'notification',
            title: `Access denied`,
            type: 'warning',
            text: exists ? `You don't have access to this user.` : `User does not exist`
          })
          await this.$sleep(500);
          this.$router.push('/users')
        }
      },
      updateUser: function (){
        if(!this.formControl.profile)
          return ''
        this.errors = []
        if(this.internalUser.name.trim() === '')
          this.errors.push('User name cannot be empty')
        if(this.internalUser.email.trim() === '')
          this.errors.push('Email address cannot be empty')
        if(this.internalUser.resetPassword.trim().length > 0){
          if(this.internalUser.resetPassword.trim().length < 6)
            this.errors.push('Password must have 6 or more characters')
          if(this.internalUser.confirmPassword.trim() !== this.internalUser.resetPassword.trim())
            this.errors.push('Confirm password does not match new password')
        }
        if (this.errors.length > 0){
          this.errors.reverse().forEach(error => {
            this.$alertify({
              group: 'notification',
              title: 'User Update',
              type: 'warn',
              text: error
            })
          });
          return false;
        }
        else {
          this.submitUser()
        }
      },
      async submitUser(){
        this.loader(true)
        try {
          const response = await this.$http.put(
            `${this.$apiEndpoint}/v1/users/${this._id}`,
            {
              user: {
                name: this.internalUser.name ? this.internalUser.name.trim() : '',
                phoneNumber: this.internalUser.phoneNumber ? this.internalUser.phoneNumber.trim() : '',
                mobileNumber: this.internalUser.mobileNumber ? this.internalUser.mobileNumber.trim() : '',
                receiveEmail: this.internalUser.receiveEmail,
                isAgent: this.userRole.data.isAgent,
                roles: this.userRole.data.role,
                resetPassword: this.internalUser.resetPassword.trim() === "" ? null : this.internalUser.resetPassword.trim(),
                avatar: this.internalUser.avatar,
                restrictDelete: this.internalUser.restrictDelete,
                restrictReassign: this.internalUser.restrictReassign
              },
              _id: this._id
            },
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          if(response.data.user && response.data.user._id) {
            this.getUser()
            this.$alertify({
              group: 'notification',
              title: 'Update successful',
              type: 'success',
              text: 'Your changes have been saved.'
            })
          }
        } catch (error) {
          this.loader(false)
          console.log(error)
          this.$alertify({
            group: 'notification',
            title: 'Update Failed',
            type: 'warn',
            text: 'Something went wrong, please try again.'
          })
        }
      },
      toggleAvatar: function(){
        this.$modal.toggle('avatar-menu');
      },
      setAvatar: function(avatar){
        this.internalUser.avatar = avatar
        this.toggleAvatar()
      },
      addBUnit: async function(){
        if(!this.formControl.profile)
          return ''
        if(!this.bunit.data)
          this.$alertify({
            group: 'notification',
            title: 'Update Failed',
            type: 'warn',
            text: 'Select a business unit to add first'
          })
        this.internalUser.bunit.push(this.bunit.data)
        await this.updateUserBUnit(`${this.internalUser.name}'s access to ${this.bunit.text} has been added`)
        this.bunit = {}
      },
      removeBUnit: async function(bunit){
        if(!this.formControl.profile)
          return ''
        this.internalUser.bunit = this.internalUser.bunit.filter(r => r._id !== bunit._id)
        await this.updateUserBUnit(`${this.internalUser.name}'s access to ${bunit.name} has been removed`)
      },
      updateUserBUnit: async function(message){
        this.loader(true)
        try {
          const response = await this.$http.put(
            `${this.$apiEndpoint}/v1/users/${this._id}`,
            {
              user: {
                bunit: this.internalUser.bunit.map(r => r._id)
              },
              _id: this._id
            },
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          if(response.data.user._id) {
            this.getUser()
            this.$alertify({
              group: 'notification',
              title: 'Update successful',
              type: 'success',
              text: message || 'Your changes have been saved.'
            })
          }
        } catch (error) {
          this.loader(false)
          console.log(error)
          this.$alertify({
            group: 'notification',
            title: 'Update Failed',
            type: 'warn',
            text: 'Something went wrong, please try again.'
          })
        }
      },
      setForms: function(){
        if(this.$userData.user.role === 'Admin')
          this.formControl = {
            profile: true,
            resetPassword: true,
            avatarMenu: true,
            status: true
          }
        else
          this.formControl = {
            profile: false,
            resetPassword: false,
            avatarMenu: false,
            status: false
          }
      },
    },
    mounted: function() {
      document.title = this.internalUser._id ? `${this.internalUser.name} | iRipple Helpdesk` : `Users | iRipple Helpdesk`;
      this.getUser()
    },
    filters: {
      zeroPad: function(num){
        return String(num).padStart(4, '0')
      },
      rounded: function(num){
        return Math.round((num + Number.EPSILON) * 100) / 100
      }
    }
  }
</script>
